.imageStackContainer{
    /* position: relative; */
    position: absolute;
    bottom: 0;
    background: red;
    height: 345px;
    /* padding-top: 4vh; */
    /* margin-top: 300px; */
    /* z-index: 2; */
}
.imageStack {
    opacity: 1;
    /* height: 150px; */
    /* background: #a790a5; */
    /* border: 3px dotted #895d75; */
    /* width: 200px; */
    position: absolute;
    /* top: 0; */
  }
  
  .imageStack:nth-child(1) {
    left :0;
    z-index: 9;
    margin-top: -100px;
  }
  .imageStack:nth-child(2) {
    left: 50px;
    z-index: 8;
    margin-top: -60px;
  }
  .imageStack:nth-child(3) {
    left: 100px;
    z-index: 7;
    margin-top: -40px;

  }  
  .imageStack:nth-child(4) {
    left: 150px;
    z-index: 6;
    margin-top: -20px;

  }  .imageStack:nth-child(5) {
    left: 200px;
    z-index: 5
  }


  