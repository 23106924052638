#homepage{
  .imageStackContainer{
    padding-top: 0;
  }
}
.white{
    color: white;
}

.box-red{
    background-color: red;
    width: 100;
    height: 100;
    border-radius: 10px;
}
.homeMiddle{
    padding-top: 16vh;
}
.homeMiddle .descriptionsection{
  align-items: flex-start;
}
.contendsection{
    display: flex ;
    padding:0px 20px;
    flex-direction: column;
    z-index: 2;
  }
.descriptionsectionWrapper{
    flex:1;  
  }
  .descriptionsection{
    display:flex;flex-direction:column
  }
.RightsideWrapper{
        flex:1;   
     }
   
    
   