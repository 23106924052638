#process-page{
    .white{
        color: white;
      }
      
      .box-red{
        background-color: red;
        width: 100;
        height: 100;
        border-radius: 10px;
      }
      
      .contendsection{
        display: flex ;
        margin-top: 16vh;
      }
      
      .descriptionsectionWrapper{
        flex:1;
      }
      .descriptionsection{
        display:flex;flex-direction:column
      }
      
      .RightsideWrapper{
            flex:1;   
         }
       
}