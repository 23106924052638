.imageStackContainer.process{
    padding-top: 0px;
    margin-top: 32vh;
    position: relative;
    // bottom: 0;
    background-color: transparent;
    img.imageStack {
        width: 11vw;
    }
    .imageStack:nth-child(1) {
        left :0;
        z-index: 8;
        margin-top: -100px;
      }
      .imageStack:nth-child(2) {
        left: 50px;
        z-index: 9;
        margin-top: -150px;
        width: 15vw;
      }
}
