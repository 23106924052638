#woldMap-wrapper{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}
#woldMap-wrapper svg{
    width: 100%;
    height: 100%;
}

#connected-points{
   
}

#connected-path{
    stroke-dasharray: 010;
    stroke-width: 12;
    animation: updatepath linear 5s infinite;
    transition: width 2s, height 4s;
}

#connected-path:hover {
    width: 300px;
    height: 300px;
  }

#outer-ring{
animation: updatering 12s linear infinite;
transform-origin: 50% 50%;
z-index: 1;

}
@keyframes updatering{
    0%   { transform: scale(1.1)   translate(10px, -30px) rotate(10deg);}
  38%  { transform: scale(1.2) translate(2vw, 2vh) rotate(160deg); }
  40%  { transform: scale(1.3) translate(2vw, 2vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 3vh) rotate(-20deg); }
  80%  { transform: scale(1.2) translate(0vw, 3vh) rotate(-20deg); }
  100% { transform: scale(1.1)   translate(10px, -30px) rotate(20deg); }
}

@keyframes updatepath{
    from{
        transform: translateX(0px);
        stroke-dasharray:25;

    }
    to {
        transform: translateX(3px);
        stroke-dasharray:45

    }
}