.white{
    color: white;
  }
  
  .box-red{
    background-color: red;
    width: 100;
    height: 100;
    border-radius: 10px;
  }
  
  .aboutsection{
    padding-top: 16vh;
}
.aboutsection .descriptionsection{
  align-items: flex-start;
}
  .descriptionsectionWrapper{
    flex:1;
  }
  .descriptionsection{
    display:flex;flex-direction:column
  }
  
  .RightsideWrapper{
        flex:1;   
     }
   
  
