#contact-page{
  .box-right{
    z-index: 2;
    position: absolute;
  }
}
.contact{
  padding-top: 16vh;
  .contactMiddle{
    .contendsection{
      padding: 0px;
      .descriptionsection{
        align-items: flex-start;
        .FirstHeadline{
          text-align: left;
        }
      }
      .arraowbtn{
        .LinkBtn{
        background-color:rgb(20, 20, 20);
        border-radius: 5%;
        border-color: aquamarine;
        padding: 0px 20px;
       }
       .LinkBtnText{
        text-align: center;
        font-weight: 500;
        font-size:1rem;
        color: rgb(255, 255, 255);
       }
        }
    }
  }
}

.emailtag{
font-weight: 400;
color:#fff;
font-size: 1rem;
}
  
 