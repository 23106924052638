.portpolio{
  .portfoliotNextbtn{
    margin-top: 150px;
  }
  .imageStackContainer{
    padding-top: 15vh;
  }
} 

.portpolio{
  padding-top: 16vh;
  .portpolioMiddle{
    .contendsection{
      padding: 0px;
      padding-right: 30px;
      .descriptionsection{
        align-items: flex-start;
        .FirstHeadline{
          text-align: left;
        }
       
      }
      .arraowbtn{
        .LinkBtn{
        background-color:rgb(20, 20, 20);
        text-align: center;
        font-weight: 400;
        font-size:14px;
        border-radius: 3%;
        border-color: aquamarine;
        padding: 0px 20px;
        color: rgb(255, 255, 255);
       }
        }
    }
  }
}

 
  
  /* .contendsection{
    display: flex ;
    padding:0px 20px;
    flex-direction: column;
  }
  
  .descriptionsectionWrapper{
    flex:1;
  }
  .descriptionsection{
    display:flex;flex-direction:column
  }
  
  .RightsideWrapper{
        flex:1;   
     }
    .arraowbtn{
        margin: 20px 0px;
    }
   .Nextbutton{  
    background-color:#fff
   }
   .Backbutton{
    background-color:#fff
   }
   .NormalText{
    font-size:12px;
    color: #BABABA;
    margin: 20px 0px;
    line-height: 20px
   } */
  