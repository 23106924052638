.imageStackPortpolioContainer{
    position: absolute;
    padding-top: 20vh;
    height: 345px;
    .imageStack {
      opacity: 1;
      position: absolute;
    }
   .imageStack:nth-child(1) {
      height: 200px;
      width: 320px;
      z-index: 0;
      // left: 20px;
    }
    .imageStack:nth-child(2) {
      z-index: 0;
      // bottom: 40px;
      // left: 60px;
    }
    
  }