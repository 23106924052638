.rideCycle {
    /* max-width: 300px; */
    width: 200px;
    flex-direction: column;
    height: 300px;
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: yellow;
    display: flex;
}

#right-wheel,#left-wheel{
    animation: wheel linear 1s infinite;
    transform-origin: center;
    transform-box: fill-box;
}

@keyframes wheel{
    from{
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);

    }
}