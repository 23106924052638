.contentSectionLeft-left{
  flex:2;
}
.navHeaderWrapper {
    display: flex;
    flex-direction:row;
    align-items:center;
    padding: 0.05rem;

    .logoWrappersection {
      display: flex;
      flex:1;
      .logoWrapper {
        // flex:3;
        height: auto;
        position: absolute;
        top: -153%;
        left: 20%;
        #CODEGRABS-logo {
          width: 100%;
      }
      }
    .emptySapce {
      flex: 2 ;
    }
  
    }

    .fa, .fas {
      font-weight: unset;
    } 

    .menuUl {
      list-style:none;
      flex:1;
      .menuLi   { 
        display:inline-block;
        font-family: Rubik;
        line-height: 34px;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 0.75rem;
        margin-right:20px;

        .headerlink{
          color: #fff;
        }
        .headerlink:hover{
          color: #e82967;
        }

    };
      }
 
      .cls-1 {
        fill: none;
      }

      .cls-2 {
        fill: #fff;
      }

      .cls-3 {
        clip-path: url(#clip-path);
      }

      .cls-4 {
        clip-path: url(#clip-path-2);
      }

      .cls-5 {
        clip-path: url(#clip-path-3);
      }

      .cls-6 {
        clip-path: url(#clip-path-4);
      }

      .cls-7 {
        clip-path: url(#clip-path-5);
      }
  }

