/* @import url('https://fonts.googleapis.com/css?family=Poppins:700'); */
.blob-wrapper {
  margin: 0;
  padding: 0;
  /* width: 100vw; */
  height: 60vh;
  overflow: hidden;
  position: relative;
  /* background: #FFB1A4; */
  
  display: flex;
  align-items: center;
}

h1 {
  color: white;
  font-size: 20vmin;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  padding-left: 40px;
}

.blob {
  position: absolute;
  /* fill: #023F92; */
  /* width: 20vmax; */
  z-index: 1;
  top: 0;
  width: 35vmax;
  right: 0;
  /* animation: move 12s linear infinite; */
  /* transform-origin: 50% 50%; */
}
.blob svg{ 
  /* width: 100%; */
}
/* .blob {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  z-index: 1;
  animation: move 4s linear;
  transform-origin: 50% 50%;
}

@keyframes move {
  0%   { transform: scale(0.5)   translate(10px, -30px) rotate(10deg); opacity: 0.1;}
  38%  { transform: scale(0.5) translate(2vw, 2vh) rotate(160deg);opacity: 0.3; }
  40%  { transform: scale(0.6) translate(2vw, 2vh) rotate(160deg);opacity: 0.5; }
  78%  { transform: scale(0.7) translate(0vw, 3vh) rotate(-20deg);opacity: 0.5; }
  80%  { transform: scale(0.8) translate(0vw, 3vh) rotate(-20deg);opacity: 0.7; }
  100% { transform: scale(1)   translate(10px, -30px) rotate(20deg);opacity: 1; }
} */