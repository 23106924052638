.BackButtonWrapper {
    .BackbtnLink {
      margin: 15px 0px;
      color: #fff;
      
      svg {
          width: 6.2vh;
          height: 6.2vh;
          border-radius: 50px;
        background-color:rgb(20, 20, 20);
        z-index: 9999;
        transform: rotateZ(180deg);
        path{
          fill: white;
        }
      }
      svg:hover {
          width: 6.5vh;
          height: 6.5vh;
          border-radius: 32px;
          background-color:#e82967;
        }
    }
  }
  